import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Gallery from '@browniebroke/gatsby-image-gallery';
import BeforeAfterSlider from 'react-before-after-slider';
//import ProjectImg from '../Image/ProjectImg';
import Title from '../Title/Title';
import '@browniebroke/gatsby-image-gallery/dist/style.css';

const Paragraphs = styled.div`
justify-content:center;
    p {
      text-align: start;
      font-size: 3rem;
      color: #333333;
      &.classy-paragraph {
        color: brown;
      }
      &#unique-paragraph {
        background: aliceblue;
      }
    }
  }
`;

const GALKON = styled.div`
  border-radius: 10px 10px 10px 10px;
  background-color: #333333;
  height: auto;
  width: 100%;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  padding: 3em;
  margin: 5em auto;
`;

const before = 'https://i.ibb.co/y8Sb6M1/before.jpg';
const after = 'https://i.ibb.co/Vg0SM9D/after.jpg';

const Galeria = () => {
  const data = useStaticQuery(graphql`
    query {
      most: allFile(filter: { absolutePath: { regex: "/gallery/most/" } }, sort: { fields: name }) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      budynek: allFile(
        filter: { absolutePath: { regex: "/gallery/budynek/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      pojazd: allFile(
        filter: { absolutePath: { regex: "/gallery/pojazd/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <section id="cien_galeria">
        <section id="galeria">
          <Title title="Nasze realizacje" />
        </section>
        <div style={{backgroundColor: '#333333'}}><br />
        </div>
      </section>
      <Container>
        <Paragraphs>
          <p><br/>Renowacja zakładu Kofama Koźle S.A. w Kędzierzynie-Koźlu</p>
          <GALKON>
            <Gallery images={data.budynek.edges.map(({ node }) => node.childImageSharp)} />
          </GALKON>
          <p>Amfiteatr w Białej Podlaskiej</p>
          <GALKON>
            <Gallery images={data.most.edges.map(({ node }) => node.childImageSharp)} />
          </GALKON>
          <p>Czyszczenie zabudowy samochodu transportującego zwierzęta.</p>
          <GALKON>
            <Gallery images={data.pojazd.edges.map(({ node }) => node.childImageSharp)} />
          </GALKON>
        </Paragraphs>
      </Container>
    </>
  );
};
export default Galeria;
