import React, { useState, useEffect } from 'react';
import Galeria from './Galeria/Galeria';
import Footer from './Footer/Footer';
import Nav from './Nav/Nav';

import { PortfolioProvider } from '../context/context';

import { projectsData, footerData, logoData } from '../mock/data';

function Realizacje() {
  const [projects, setProjects] = useState([]);
  const [footer, setFooter] = useState({});
  const [galeria, setGaleria] = useState({});
  const [logo, setLogo] = useState({});

  useEffect(() => {
    setLogo({ ...logoData });
    setProjects([...projectsData]);
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ logo, projects, galeria, footer }}>
      <Nav />
      <Galeria />
      <Footer />
    </PortfolioProvider>
  );
}

export default Realizacje;
